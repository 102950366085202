import React, { useEffect, useState }  from "react";
import { Tabs, Table, Button, Spin} from "antd";
import { UserSwitchOutlined} from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { convertFloatDotSeperated } from "../helpers/common";
import {getInvoiceApi, calculateTotalApi, getDiscountsApi} from '../services/invoices';
import AmountReceived from '../components/AmountReceived';
import { LocalStorageService } from "../helpers/LocalStorage";
import {applicationStatusMapByValue} from "../helpers/common";
import HeaderUser from "../components/HeaderUser";


const InvoicesApplication = () => {
  let navigate = useNavigate();
  const userText = 'Bu sayfadan alıcınız tarafından yüklenmiş olan faturalarınız görebilir, istediğiniz faturayı iskonto ettirebilirsiniz.'; 
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState<any>(false)
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [psizeDis, setPsizeDis] = useState<any>(10);
  const [pageDis, setpageDis] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [totalDataCountDis, setTotalDataCountDis] = useState<any>(1);
  const [invoices, setInvoices] = useState<any>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<any>([]);
  const [discounts, setDiscounts] = useState<any>([]);
  const [calculatedDiscount, setCalculatedDiscount] = useState<any>({});
  const [tabKey, setTabKey] = useState<any>( LocalStorageService.getTabKey());
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [total, setTotal] = useState<any>(0);
  const [buyerId, setBuyerId] = useState<any>();
  const [loadingx, setLoadingx] = useState(false);
  const userName = LocalStorageService.getUserName();
  LocalStorageService.setTabKey(tabKey)

  const getInvoice = async () => {
    try{
      setLoading(true)
      const response:any = await getInvoiceApi(page,psize);
      if(response){
        setInvoices(response.invoices.data);
        setTotalDataCount(response.invoices.totalDataCount);
        setBuyerId(response.invoices.data[0].buyerId)
        setLoading(false)
      }
    }catch(e:any){
      console.log(e);
      setLoading(false)
    }
  }

  const getDiscounts = async () => {
    try{
      setLoading(true)
      const response:any = await getDiscountsApi(pageDis,psizeDis);
      if(response){
        setDiscounts(response.discounts.data);
        setTotalDataCountDis(response.discounts.totalDataCount);
        setLoading(false)
      }
    }catch(e:any){
      console.log(e);
      setLoading(false)
    }
  }

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  const handleShowSizeDis = (current: any, size: any) => {
    setpageDis(current);
    setPsizeDis(size);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys:any, selectedRows:any) => {
      setSelectedRowKeys(newSelectedRowKeys); 
      calculateTotal(selectedRows);
    },
  };

  const calculateTotal = async (data:any) => {
    setSelectedInvoices(data);
    const discountInvoices:any = [];
    data && data.map((item:any) => {
      discountInvoices.push({
        invoiceTerm: moment(item.invoiceTerm),
        invoiceAmount: item.invoiceTotal
      })
      return true
    });
   
    if(discountInvoices.length > 0){
      const response:any = await calculateTotalApi({buyerId, discountInvoices})
      if(response){
        setTotal(response.calculatedDiscountAmounts.calculatedSumAmount);
        setCalculatedDiscount(response.calculatedDiscountAmounts)
      }
    }else{
      setTotal(0)
    }
     
    };

  const columns : any = [
    {
      title: <>Fatura <br /> No</>,
      dataIndex: 'invoiceNumber',
      key: "invoiceNumber",
      fixed: 'left',
      width:'132px'
    },
    {
      title:  <>Fatura <br /> Tarihi</>,
      dataIndex: 'invoiceDate',
      key: "invoiceDate",
      width:'80px',
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: <>Fatura <br /> Vadesi</>,
      dataIndex: 'invoiceTerm',
      key: "invoiceTerm",
      width:'80px',
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: <>Tutar <br /> (TL)</>,
      dataIndex: 'invoiceTotal',
      key: "invoiceTotal",
      width:'100px',   
      render: convertFloatDotSeperated,
    },
  ];

  const columnsApplication : any = [
    {
      title: <>Başvuru <br /> No</>,
      dataIndex: 'applicationNumber',
      key: "applicationNumber",
      fixed: 'left',
      width:'45px'
    },
    {
      title:  <>Başvuru <br /> Tarihi</>,
      dataIndex: 'applyDate',
      key: "applyDate",
      width:'60px',
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: <>Durum <br /></>,
      dataIndex: 'status',
      key: "status",
      render: (value:any) => <span style={{color:applicationStatusMapByValue[value].color}}>{applicationStatusMapByValue[value].text}</span>,
      width:'70px',
    },
    {
      title:'',
      dataIndex:'detail',
      key:'detail',
      width:'100px',   
      render: (text: any, row: any) =>  
        <Button style={{fontSize:'11px'}}
          type="primary" 
          onClick={()  => navigate(`/application-detail/${row?.id}`)}
          > Detay
        </Button>         
  },
  ];

  const handleClickBuyerList = () => {
    navigate('/buyer-list'); 
    LocalStorageService.setTabKey('1')
  };

  useEffect(() => {
    if(tabKey === '2'){
      getDiscounts();  
      setSelectedRowKeys([]);
      setTotal(0);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[tabKey,pageDis,psizeDis])

  useEffect(() => {
    getInvoice();
     // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[page,psize])


  return (
    <Spin spinning={loadingx} tip="Lütfen Bekleyiniz..." size="large" >
      <div className="routing-space">
        <Button
          type="primary"
          icon={<UserSwitchOutlined />}
          onClick={handleClickBuyerList}
        >
          Alıcı Değiştir
        </Button>
      </div>
      <HeaderUser userName={userName} userText={userText}/>
      <div className="tab-model" style={{ marginTop: '20px' }}>
        <Tabs defaultActiveKey="1" activeKey={tabKey} onChange={(key) => setTabKey(key)} >
          <TabPane tab="Faturalarım" key="1">

            <Table
              className="w-100 invoiceTable"
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{
                position: ["topRight", "none" as any],
                showLessItems: true,
                showSizeChanger: true,
                current: page,
                pageSize: psize,
                total: totalDataCount,
                onChange: handleShowSize,

              }}
              loading={loading}
              rowKey="id"
              columns={columns}
              dataSource={invoices}
              scroll={{
                x: 450,
              }}
            />
            <div style={{ marginTop: '40px' }}>
              <AmountReceived 
                total={total}  
                calculatedDiscount={calculatedDiscount} 
                invoices={selectedInvoices}  
                setTabKey={setTabKey}
                setLoadingx={setLoadingx}
                 />
            </div>
          </TabPane>
          <TabPane tab="Başvurularım" key="2">
            <Table
              className="w-100 invoiceTable"
              pagination={{
                position: ["topRight", "none" as any],
                showLessItems: true,
                showSizeChanger: true,
                current: pageDis,
                pageSize: psizeDis,
                total: totalDataCountDis,
                onChange: handleShowSizeDis,
              }}
              loading={loading}
              columns={columnsApplication}
              dataSource={discounts}
              scroll={{
                x: 450,
              }}
            />
          </TabPane>
        </Tabs>
      </div>
     
    </Spin>
  );
};

export default InvoicesApplication;
